.base-bg{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: url('offline-background.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
}
.one-bg{

  justify-content: center;
  align-items: center;
  .one-title{
    display: flex;
    justify-content: center;
    align-items: center;

    letter-spacing: 40px;
    font-size: 200px;
    color: white;
  }
}
.two-bg{

  align-items: center;
  flex-direction: column;
  .two-title{
    display: flex;
    justify-content: center;
    align-items: center;

    letter-spacing: 10px;
    font-size: 100px;
    color: white;

    margin-top: 200px;
    margin-bottom: 100px;
  }
  .two-detail{
    align-self: start;
    display: flex;
    justify-content: center;
    align-items: center;

    letter-spacing: 10px;
    font-size: 60px;
    color: white;

    margin-left: 100px;
  }
}
.three-bg{
  align-items: center;
  flex-direction: column;
  .three-title{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    letter-spacing: 10px;
    font-size: 100px;
    color: white;

  }
  .three-bottom{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .three-bottom-left{
      margin-top: 300px;
  .three-detail{
    align-self: start;
    display: flex;
    justify-content: start;
    align-items: center;

    letter-spacing: 10px;
    font-size: 70px;
    color: white;

    margin-left: 100px;
  }
}
  .three-bottom-right{
  margin-top: 50px;
    width: 685px;
    height: 680px;
    background: url('study-target-scb.png') no-repeat center center;
    background-size: 100% 100%;
    margin-right: 80px;
  }
  .three-bottom-right2{
    margin-top: 50px;
    width: 685px;
    height: 680px;
    background: url('study-target-jzjl.png') no-repeat center center;
    background-size: 100% 100%;
    margin-right: 80px;
  }
}
}
.four-bg{
}
