section {
    .ant-layout {
        overflow-x: hidden;

        .ant-layout-content {
            min-height: auto;
        }
    }
}

.page-container {
    display: flex;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 30px;
    min-height: 100%;
    justify-content: space-between;

    .page-content {
        width: 100%;
    }
}
.flex-c-b {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.flex-ctr {
    display: flex;
    align-items: center;
    justify-content: center;
}
.w100h {
    width: 100%;
    height: 200px;
    color: #999999;
}
.backLog-list {
    padding: 15px;
    width: 100%;
    height: 235px;
    margin-top: 5px;
    overflow-y: scroll;
    background-color: #ffffff;
    box-sizing: border-box;
    .backLog-item {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333333;
        cursor: pointer;
    }
    .backLog-item:hover {
        box-sizing: border-box;
        text-decoration: underline;
        color: #6887ff;
        cursor: pointer;
    }
}

.pl10 {
    padding-left: 10px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ml-20 {
    margin-left: 20px;
}

.mt-15 {
    margin-top: 15px;
}

.mr-30 {
    margin-right: 30px;
}

.p-100 {
    padding: 0 200px 0 100px;
    box-sizing: border-box;
}

.ant-btn-link {
    color: #1890ff !important;
}

.with200 {
    width: 200px;
}

.with140 {
    width: 140px;
}

.userinfo {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .user-left {
        width: 50px;
        height: 40px;
        padding-right: 10px;

        .headImg {
            width: 40px;
            height: 40px;
            border-radius: 100%;
        }
    }

    .user-right {
        display: flex;
        align-items: left;
        justify-content: space-between;
        flex-direction: column;

        .username {
            margin-left: 20px;
            padding: 0;
            margin: 0;
        }
    }
}

.table-box {
    width: auto;
    height: auto;
    border: 1px solid #252525;

    .table-item {
        width: auto;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #252525;

        .table-left {
            min-width: 160px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #252525;
        }

        .table-right {
            width: 370px;
            height: 60rpx;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-item:last-child {
        border-top: none;
        border-bottom: none;
    }
}

.relation-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.salesInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .item-info {
        width: auto;
        height: auto;
        font-size: 14px;
        margin-bottom: 10px;

        .title {
            font-size: 14px;
            line-height: 16px;
        }
    }

    .link {
        color: rgb(24, 144, 255);
        cursor: pointer;
        margin-left: 60px;
    }
}

.info-link {
    text-decoration: underline;
    color: #448eb9;
    cursor: pointer;
}

.pagination-box {
    margin-top: 10px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.w100 {
    width: 100%;
}

.with250 {
    width: 250px;
    height: auto;
}

.w200 {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    margin-right: 0 !important;
}

.nowrap {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0 !important;
}

.w280 {
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    margin-right: 0 !important;
}

.w300 {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    margin-right: 0 !important;
}

.table-top-box {
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.btn-box {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    div {
        margin-top: 5px;
    }
}
.memberSearch-box {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 8px;
}
.pointer {
    cursor: pointer;
}

.sign {
    background-color: #448eb9;
    color: #ffffff;
    padding: 2px;
    font-size: small;
    margin-right: 2px;
}

.link {
    color: #448eb9;
    cursor: pointer;
}

.border-bottom {
    border-bottom: 1px solid;
}

.link:hover {
    color: #448eb9;
    text-decoration: underline;
}

.right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tree-number-box {
    width: 100%;
    height: 45px;
    font-size: 20px;

    span {
        width: 150px;
        height: 20px;
        text-align: left;
        margin-right: 30px;
    }

    .button {
        margin-top: 5px;
        width: 72px;
        float: right;
    }
}

.table-img-box {
    width: 80px;
    height: 80px;
    img {
        width: 80px;
        height: auto;
        max-height: 80px;
    }
}
.table-img-box1 {
    width: 100%;
    height: 80px;
    cursor: pointer;
    img {
        width: 80px;
        height: auto;
        max-height: 80px;
    }
}
.poster-img-box {
    width: 80px;
    height: 80px;

    img {
        width: 80px;
        height: 80px;
    }
}

.img-preview-box {
    width: 600px;
    height: auto;

    img {
        width: 600px;
        height: auto;
    }
}

.NotRemitCount {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: left;
}
.NotRemitCount1 {
    width: 100%;
    height: 20px;
    line-height: 20px;
    margin-bottom: 10px;
}
.NotRemitCountRight {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: right;
    box-sizing: border-box;
}

.index-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        text-align: left;
    }

    .day-title {
        width: 34%;
    }

    .week-title {
        flex: 1;
    }
}

.relation-container {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 50px;

    .relation-item {
        width: auto;
        height: 30px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-box {
            width: 30px;
            height: 30px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            img {
                width: 30px;
                height: 30px;
                display: inline-block;
                border-radius: 50%;
            }
        }

        span {
            margin-left: 5px;
            width: auto;
            height: 30px;
            line-height: 30px;
        }
    }
}

// 修改密码
.pwd-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .pwd-content {
        border: 1px solid #cccccc;
        width: 450px;
        height: 350px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.flex-s-c {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
// 登录页
.login-bottom {
    position: absolute;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .copy-right {
        font-size: 16px;
    }

    .record-box {
        a {
            margin-left: 3px;
            text-decoration: none;
        }
    }
}

// 记训营
.data-container {
    .data-box {
        padding-top: 30px;
        width: 100%;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .data-item {
            width: 25%;
            height: 150px;
            background-color: #eeeeee;
            margin-right: 20px;
            padding: 30px 0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            p {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .item-data {
                font-size: 24px;
            }
        }
    }
}

.textInfoBox {
    width: 100%;
    margin-top: 50px;
    padding-left: 20px;
    box-sizing: border-box;

    .textInfoBox-title {
        width: 100%;
        height: 50px;
        line-height: 50px;
    }

    .textInfo-item {
        width: 1000px;
        height: auto;
        margin-top: 15px;

        .textInfo-title {
            width: 100%;
            height: 40px;
            line-height: 40px;
        }

        .textInfo-desc {
            width: 100%;
            height: auto;
            font-size: 14px;
            word-break: break-all;
            line-height: 16px;
            text-indent: 1em;
        }
    }
}

.y-center {
    .avatar-text {
        color: #ffffff;
    }
}

.custom-theme-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-form {
    .ant-form-item {
        width: 320px;
    }

    label {
        width: 150px;
    }
}

.studentManage {
    .ant-form-item {
        width: 400px !important;
    }

    label {
        width: 200px;
    }
}

.form-container {
    margin-left: 100px;

    .ant-form-item {
        width: 400px;
        font-size: 14px;
        line-height: 1.5;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-picker-range {
            width: 360px;
        }

        .ant-form-item-label {
            line-height: 40px;
            width: 120px;
        }
    }
}

//  记训营
.content-container {
    background-color: #ffffff !important;
    padding: 50px 30px;

    .ant-form-item {
        width: 400px;
        font-size: 14px;
        line-height: 1.5;

        .ant-picker-range {
            width: 360px;
        }

        .ant-form-item-label {
            line-height: 40px;
            width: 120px;
        }
    }

    .container-top-one {
        width: 980px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ant-form-item {
            width: 350px;
        }
    }
    .hasCjScb{
        width:250px !important;
    }
    .container-top-little {
        width: 800px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .container-top-left {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.content-container-two {
    background-color: #ffffff !important;
    padding: 50px 30px;

    .ant-form-item {
        width: 400px;
    }

    .container-top-one {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ant-form-item {
            width: 350px;
        }
    }

    .container-top-left {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.ant-modal-content {
    padding-top: 20px;
}

// 菜单管理列表
.tree-content {
    width: 1024px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tree-box {
        width: 400px;
        height: 500px;
        border: 1px solid #dddddd;
        overflow-y: scroll;
    }

    .form-box {
        width: 570px;
        height: 500px;
        border: 1px solid #dddddd;
        padding: 15px 30px;
        box-sizing: border-box;

        .title {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .ant-form-item {
        width: 500px;
    }
}

.tree-contaner {
    width: 400px;
    height: 500px;
    border: 1px solid #dddddd;
    overflow-y: scroll;
}

.table-headeImgUrl {
    width: 45px;
    height: 45px;
}

.tree-box {
    width: 100%;
    height: 200px;
    border: 1px solid #dddddd;
    overflow-y: scroll;
}

.text-link {
    color: #23527c;
    cursor: pointer;
}

.operation-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .ant-btn {
        display: flex;
        margin-top: 3px;
        align-items: center;
        margin-left: 0;
    }
}

.operation-line {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .ant-btn {
        display: flex;
        margin-top: 3px;
        align-items: center;
    }
}

.operation-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    .ant-btn {
        display: flex;
        margin-top: 3px;
        align-items: center;
        margin-left: 10px;
    }
}

.personCenter-box {
    width: 160px;
    padding-left: 10px;

    .personCenter {
        display: inline-block;
        width: 200px;
        margin-bottom: 15px;
        color: '#999999';
        margin-left: 5px;
        font-size: 15px;
        opacity: 0.5;
    }
}

.person-item {
    margin-left: 5px;
    margin-top: 10px;
}

.modal-top-search {
    padding-bottom: 10px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// 财务报表
.finance-report {
    .statistic-p {
        width: 350px;

        .statistic-span1 {
            width: 230px;
            font-size: 14px;
            display: inline-block;
            font-weight: 500;
        }
        .statistic-span3 {
            width: auto;
            font-size: 14px;
            display: inline-block;
            font-weight: 500;
            margin-right: 4px;
            margin-bottom: 10px;
        }
    }
    .statistic-p1 {
        width: 230px;
        .statistic-span2 {
            width: 100px;
            font-size: 14px;
            display: inline-block;
            font-weight: 500;
        }
    }

    .statistic {
        cursor: pointer;
    }

    .statistic:hover {
        color: #000000;
    }
}

.statistic-top-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    .statistic-item {
        width: 120px;
        height: 60px;
        margin-right: 1px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .item-left {
            padding-left: 5px;
            width: 40px;
            height: auto;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            color: #ffffff;
        }

        .item-right {
            width: 80px;
            height: 60px;
            padding-left: 10px;
            box-sizing: border-box;
            padding: 5px 0 5px 10px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            color: #ffffff;

            p {
                padding: 0;
                margin: 0;
            }

            .right-top {
                font-weight: bold;
            }
        }
    }

    .statistic-button {
        width: 120px;
        height: 60px;
        margin-right: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bg-color1 {
        background-color: #6fb3e0;
    }

    .bg-color2 {
        background-color: #d53f40;
    }

    .bg-color3 {
        background-color: #9abc32;
    }
}

// 商品管理
.tabulated-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.green {
    color: #008000;
}

.red {
    color: #ff0000;
}

.red-link {
    text-decoration: underline;
    cursor: pointer;
}

.gray {
    color: #808080;
}

.blue {
    color: #459ed1;
}

//感悟管理
.feeling-box {
    margin: 10px 0;
    padding: 10px;
}

.feeling-border-bottom {
    border-bottom: 1px solid #e8e8e8;
}

.feeling-border-top {
    border-top: 1px solid #e8e8e8;
}

.feeling-label.arrowed-in {
    margin-left: 5px;
    margin-right: 4px;
}

.feeling-label.arrowed,
.feeling-label.arrowed-in {
    position: relative;
    z-index: 1;
}

.feeling-label {
    font-size: 12px;
    line-height: 1.15;
    height: 20px;
    border-radius: 0;
    text-shadow: none;
    font-weight: normal;
    color: #ffffff;
    display: inline-block;

    padding: 0.2em 0.6em 0.3em;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.label-important,
.feeling-label.label-important,
.badge.badge-important,
.badge-important {
    background-color: #d15b47;
}

.feeling-label.arrowed-in:before {
    left: -5px;
    border-width: 10px 5px;
}

.feeling-label.arrowed-in:before {
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    border: 1px solid transparent;
    border-color: #d15b47 #d15b47 #d15b47 transparent;
    border-width: 10px 5px;
}

//活跃度分析
.analysis-card {
    background: #eeeeee !important;
    padding: 16px 0;
}

// 全局数据
.echart-box1 {
    width: 1050px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .echart-item {
        width: auto;
        height: 500px;
        margin-bottom: 30px;
        flex: 1;
        height: auto;
        border: 1px solid #cccccc;
        border-right: none;
    }

    .echart-item:last-child {
        border-left: none;
        border-right: 1px solid #cccccc;
    }
}

.echart-box {
    width: 1340px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cccccc;

    .echart-item {
        margin-bottom: 30px;
        flex: 1;
        height: auto;
    }
}

.sale-modal-box {
    width: 98%;
    height: auto;
}

//日历标识
.check-green {
    background: #f7d77f;
    color: #000000;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.ant-picker-cell::before {
    background: none;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    background: none;
    border: none;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: rgba(0, 0, 0, 0.25);
    background: none;
}

.ant-picker-calendar-date-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    box-shadow: none;
}

.expand-container {
    .ant-table-row-level-1 {
        .ant-table-cell:nth-child(2) {
            padding-left: 20px !important;
        }
    }

    .ant-table-row-level-2 {
        .ant-table-cell:nth-child(2) {
            padding-left: 30px !important;
        }
    }

    .ant-table-row-level-3 {
        .ant-table-cell:nth-child(2) {
            padding-left: 40px !important;
        }
    }

    .ant-table-row-level-4 {
        .ant-table-cell:nth-child(2) {
            padding-left: 50px !important;
        }
    }
}

//用户信息
.tree-number-total {
    width: 100%;
    font-size: 16px;
    display: flex;
    margin-bottom: 10px;
}

.tree-number-boxz {
    width: 100%;
    height: 45px;
    font-size: 20px;
}

.tree-Id {
    width: none !important;
    float: right;
}

.lottery-content {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff !important;
    padding: 50px 30px;

    &-title {
        margin-bottom: 30px;
        font-size: 48px;
    }

    &-search {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
    }
}

.travel-lottery-content {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff !important;
    padding: 50px 30px;

    &-title {
        margin-bottom: 30px;
        font-size: 48px;
    }

    &-search {
        margin-bottom: 30px;
        margin-right: 90px;
        display: flex;
        flex-direction: column;
        width: 500px;
    }
}

// 强制换行
.textvalue-desc {
    height: auto;
    word-break: break-all;
    text-indent: 1em;
}
