

 
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  // background-color:#ff4d4f !important;
  // background: #ff4d4f;
  // background: #ff4d4f !important;
  // background: #dd2528 !important;
  // background: #ff4d4f url('./../../../style/imgs/lucky_bg.png') repeat-x center center;
  background-size: 100% 100%;
  font-size: 18px;
}
//切换用户
.switch-user{
  // width: 100vw;
  position: fixed;
  top: 20px;
  right: 32px;
  // right: 55px;
  // background-color: rgba(0,0,0,.5);
  z-index: 0;
  width: 124px;
  height: 40px;
  font-size: 26px;
  // font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}

.lucky-bg{
  width: 100%;
  height: 100%;
  min-width: 1280px;
  min-height: 720px;
  background-color: #ff4d4f;
  // background: url('./../../../style/imgs/lucky_bg.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lucky-content {
  height: 100%;
  overflow: hidden;

  margin: auto;
  // padding: 50px 30px;
  width: 1280px;
  height: 720px;
  // width: 1920px;
  // height: 1080px;
  // background: #ff4d4f !important;
  background: #ff4d4f url('./../../../style/imgs/lucky_bgt.png') no-repeat center center;
  // background-size: cover;
  background-size: 100% 100%;
}
// .has-content{
//   background: #ff4d4f url('./../../../style/imgs/lucky_bgt.png') no-repeat center center;
// }



.lucky-has{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.has-gif{
  background: url('./../../../style/imgs/4.gif') no-repeat 0px 0px;
  // background: url('./../../../style/imgs/4.gif') no-repeat 4px -1px;
  // background: url('./../../../style/imgs/3.gif') no-repeat 0px -0px;
  // background: url('./../../../style/imgs/ffff.gif') no-repeat 0px -0px;
  // background: url('./../../../style/imgs/3233.gif') no-repeat 0px -0px;
  // background: url('./../../../style/imgs/11.gif') no-repeat 0px -68px;
  background-size: 100% 100%;
}

.lucky-title{
  width: 216px;
  height: 66px;
  // width: 324px;
  // height: 98px;

  // margin-top: 2%;
  // margin-top: 30px;
  margin-top: 50px;
  // margin-top: 82px;
  background: url('./../../../style/imgs/lucky_title.png') no-repeat center center;
  background-size: 100% 100%;
}
.zyfy-logo{
background: url('./../../../style/imgs/lucky_zyfy.jpg') no-repeat center center;
 background-size: 100% 100%;
}
.top-lamp{
  width: 614px;
  height: 262px;
  // width: 922px;
  // height: 394px;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.has-top{
  background: url('./../../../style/imgs/lucky-top.png') no-repeat -2px 8px;
  // background: url('./../../../style/imgs/lucky-top.png') no-repeat -4px 12px;
  background-size: 100% 100%;
}
.lucky-search{
  display: flex;
  align-items: center;
}
.lucky-userinfo{
  height: 32px;
  font-size: 28px;
  // font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFECBE;
  display: flex;
  align-items: center;
  .nick{
    // transform: rotate(-6deg);
  }
  .real{
    // transform: rotate(6deg);
  }
}


.side-lamp{
  width: 574px;
  height: 247px;
  // width: 860px;
  // height: 370px;
}
.has-side{
  background: url('./../../../style/imgs/lucky-side.png') no-repeat 8px center;
  // background: url('./../../../style/imgs/lucky-side.png') no-repeat center center;
  background-size: 100% 100%;  
}
.award{
  width: 65%;
  // width: 65%;
  height: 100%;
  margin-right: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lucky-box{
  width: 79%;
  // width: 100%;
  // width: 850px;
  height: 241px;
  // height: 361px;
  // border: 1px solid black;
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  display: flex;
  // align-items: center;
  justify-content: center;
  font-size: 20px;
  // margin-top: 8%;
  margin-top: 14px;
  // margin-top: 18px;
  // margin-top: 60px;
  .lucky-list-box{
    width: 88%;
    border-radius: 15px 20px 17px 12px;
    position: initial;
    margin-left: 20px;
    z-index: 24;
    box-shadow:inset 0px 0px 0px 0px #b30000,
    inset 0px 0px 65px 0px #b30000,
    inset 0px 0px 0px 0px #b30000,
    inset 0px 0px 12px 0px #b30000;
  }
  .lucky-list{
    width: 89%;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }

}


.lucky-item{
  width: 76%;
  // width: 61%;
  // width: 54%;
  // min-width: 640px;
  // max-width: 54%;
  // width: 624px;
  height: 122px;
  // height: 171px;
  // margin-top: 7%;
  margin-top: 30px;
  // margin-top: 50px;
  background: url('./../../../style/imgs/lucky_item.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .prizeName{
    font-size: 28px;
    // font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FEFEFE;
  }
}



.lucky-btn{
  width: 75%;
  // width: 921px;
  height: 120px;
  // height: 181px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  background: url('./../../../style/imgs/lucky_button.png') no-repeat center center;
  background-size: 100% 100%;
  .btn-start{
    width: 80%;
    text-align: center;
    color: #FFFFFF;
    height: 52px;
    // height: 76px;
    font-size: 54px;
    // font-size: 80px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 48px;
    text-shadow: 0px 6px 6px rgba(207, 1, 22, 0.31);
  }
}



 
// 奖品的弹窗信息
.conter-dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 999;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .dialog-flex{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialog-box{
    width: 1280px;
    // height: 720px;
    // width: 1920px;
    // height: 1080px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-title{
      width: 279px;
      height: 54px;
      // margin-top: 240px;
      background:  url('./../../../style/imgs/lucky_madeT.png') no-repeat center center;
    }
    .dialog-close{
      font-size: 30px;
      width: 58px;
      height: 58px;
      // display: flex;
      // align-items: center;
      // justify-content: flex-end;
      position: relative;
      top: -28px;
      background:  url('./../../../style/imgs/lucky_close.png') no-repeat center center;
    }
    .dialog-content{
      width: 517px;
      height: 514px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url('./../../../style/imgs/lucky_mode.png') no-repeat center center;
      .dialog-name{
        width: 394px;
        height: 344px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding-top: 180px;
        padding-bottom: 10px;
        p{
          width: 100%;
          height: auto;
          text-align: center;
          color: #F00202;
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          margin-bottom: 0;
          line-height: 28px;
          padding: 0 2px;
          box-sizing: border-box;
        }
        .font24{
          font-size: 19px;
          line-height: 19px;
        }
        .img-box{
          width: 100%;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: auto;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .dialog-footer{
        height: 120px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
 
.animate{
  // transition:all .5s;
}

.opacityAnimation{
  // animation:opacityAnimation .2s 1;
  // -webkit-animation:opacityAnimation .2s 1; 
}
@keyframes opacityAnimation{
  from {opacity:0;}
  to {opacity:1;}
}
@-webkit-keyframes opacityAnimation {
  from {opacity:0;}
  to {opacity:1;}
}
























































.box-old{
  width: 600px;
  height: 228px;
  border: 1px solid black;
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.box{
  width: 600px;
  height: 800px;
  border: 1px solid black;
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
 
.content{
  position: absolute;
  top: 0px;
}
 
.row{
  height: 20px;
  margin: 5px auto;
}






.shade {
  background-color: #FFF600;
  font-size: 24px;
}
.shade.show {
  background-color: rgba(250, 250, 250, .4);
}

.prizeName {
  display: block;
  text-align: center;
}



.list-item{
  width: 450px;
  height: 100px;
  background: orange;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}





.award-btn{
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-start{
    width: 100px;
    text-align: center;
    color: #FFFFFF;
    background: #ff4d4f;
  }
}

.prize-list-box{

  .prize-list-title {
    text-align: center;
  }
  .prize-list-content {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    overflow: hidden;
    font-size: 18px;
    &>div {
      flex: 1; 
    }
    
  }
}



.flash {
  // background: #FFF600;
	animation-delay:1s;
  -webkit-animation-name: mymove;
  animation-name: mymove;
}
@keyframes mymove
{
	from {top:-174px;}
	to {top:-0px;}
}










 
// 奖品的弹窗信息
.common-dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 999;
  .dialog-box{
    background-color: #fff;
    padding: 20px;
    // width: 50vw;
    width: 600px;
    height: 300px;
    margin: 226px auto;
    .dialog-close{
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .dialog-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      .dialog-title{
        font-size: 38px;
        font-weight: 600;
        margin: 20px 0 30px 0;
      }
    }
  }
}
 