/*
 * File: app.less
 * Desc: 描述
 * File Created: 2020-07-26 18:27:37
 * Author: yezi
 * ------
 * Copyright 2020 - present, yezi
 */
@prefix: app;

.@{prefix} {
    &_layout {
        flex-direction: column;
        &_content {
            margin: 0 16px;
            overflow: initial;
            flex: 1 1 0;
        }
        &_foot {
            text-align: center;
        }
    }
}
.p-bg {
    background: url('./imgs/pwd_bg.jpg') no-repeat center center;
}
