
.back-ground{
  width: 100%;
   height: 100%;
   min-width: 1280px;
   min-height: 100vh;
background: url('offline-background.png') no-repeat center center;
 background-size: 100% 100%;
 display: flex;
 flex-direction: column;
 .top{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .top-top{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  .class-title{
    font-size:60px;
  color: rgba(260, 134, 28, 1);
  }
  .title-one{
margin-right: 80px;
  }
  .title-two{
    margin-left: 80px;
  }
  }
  .top-bottom{
    margin: 30px 0;
    font-size:50px;
    color: rgba(255, 255, 255, 1);
  }
 }
 .bottom{
  display: flex;
  justify-content: center;
  
  

 }
 .class-div{
height: 100%;
width: 48%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;


.class-content{
  height: 750px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .class-title-qrcode{
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    margin-bottom: 200px;
    .class-img{
      height: 100%;
      display: flex;
      align-items: center;
    }
    .class-classinfo{
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      font-size:40px;
      color: rgba(255, 255, 255, 1);
      .class-classinfo-amount{
        margin-bottom: 20px;
      }
    }
  }
  :root {  
    --animation-duration-one: 9s;  
    --animation-duration-two: 9s;  
  }
  .class-content-buyer{
      height: 700px;
      width: 350px;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      //justify-content: center;
    align-items: center;

.class-content-move{
    //height: 100%;
    width: 300px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  
}
.move-one{
  animation: marquee-two var(--animation-duration-one) linear infinite;
}
.move-two{
  animation: marquee-two var(--animation-duration-two) linear infinite;
}
.no-height{
  height: 0px;
}
      .buyer{
        width: 300px;
        height: 140px;
        font-size:60px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        flex-shrink: 0;// 禁止缩放盒子
        white-space: nowrap;
        overflow: hidden;
        line-height: 140px;
      }
  }
  .class-content-qrCode{
      width: 500px;
      height: 500px;
  }
}
.content2{
  display: flex;
  justify-content: flex-end;
}
 }
 }

 //定义动画效果开始的位置以及消失的位置
@keyframes marquee-two {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}