img {
  vertical-align: middle;
}
.img-logo50{
  width: 50px;
  height: 50px;
}
.img-responsive{
  width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
