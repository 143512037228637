.back-ground-one{
 width: 100%;
  height: 100%;
  min-width: 1280px;
  min-height: 720px;
//background-color: rgba(3, 14, 67, 1);
background: url('offline-apply-background.png') no-repeat center center;
 background-size: 100% 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

.title{
font-size:60px;
color: rgba(260, 134, 28, 1);
//margin-bottom: 30px;
}
.target-tip{
  font-size:40px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 30px;
}
.buyer-list{
  width: 90%;

  //margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  .buyer{
    font-size:60px;
    color: rgba(255, 255, 255, 1);
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    //-webkit-animation: 5s rowup linear infinite normal;
    //animation: 5s rowup linear infinite normal;
    //position: absolute;
    //animation: slide 5s linear infinite;
  }
}


.qrcode{
  height: 500px;
  width: 500px;
  margin-top: 50px;
//background: url('img.png') no-repeat center center;
 //background-size: 100% 100%;
}


@keyframes rowup {
  0% {
   // -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    //-webkit-transform: translate3d(-150px,0 , 0);
    transform: translate3d( -300px,0, 0);
  }
}



.target-top {
  position: relative;
  overflow: hidden;
  display: inline-flex;
}

.img-right {
  position: relative;
  //left: 10px; // 这里的间距可以根据你想要的宽度自行调整
}

:root {  
  --animation-duration: 9s;  
}

.target-img {
 // width: 100%;
  height: 110px;
  // display: flex;
  display: inline-flex;
  //justify-content: space-between;
  animation: marquee var(--animation-duration) linear infinite;
  color: red;
  font-size: 60px;
  //border: 1px red solid;

  > div {
    width: 250px;
    height: 110px;
    flex-shrink: 0;// 禁止缩放盒子
  }
}
//定义动画效果开始的位置以及消失的位置
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.black-div{
  height: 150px;
  width: 500px;
  margin-top: 30px;
   display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      font-size:40px;
      color: rgba(255, 255, 255, 1);
}
}